@import "whiteglass";
.bt {
  border-collapse: collapse;
  border: 1px solid #d0d6db;
}

.bt th, .bt td {
  padding: 0.2rem 0.2rem;
  vertical-align: top;
  border: 1px solid #d0d6db;
}

.bt thead th {
  vertical-align: bottom;
  border-bottom-width: 2px;
}

img + em {
  display: block;
  text-align: center;
}
